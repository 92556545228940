import React from 'react';
import { graphql } from "gatsby";

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import style from './style.module.scss';

import IndyLink from 'components/IndyLink';
import { SEO } from 'components/Helmets';
import { getModIconData } from 'components/ModuleIcon';

import docManifest from 'src/content/docs/manifest.json';

export default function DocSectionPage({pageContext, data}){
    const module = pageContext.module.toLowerCase();

    if (!docManifest.hasOwnProperty(module) || !docManifest[module].hasOwnProperty('posts')){
        throw new Error('Document section is not in manifest');
    }
    const docMani = docManifest[module].posts;

    const pagePath = "/learn/"+module;

    let docs = data.allMarkdownRemark.nodes;

    // Customized title + SEO
    let title = docManifest[module].title;
    let descr = docManifest[module].descr;

    let previewImgKey;
    if (module == "general"){
        previewImgKey = "default";
    }else{
        previewImgKey = module;
    }

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title={title + " - Mashoom"}
                description={descr}
                previewImgKey={previewImgKey}
                path={pagePath}
            />

            <PagePicTitle
                imageCmp={getModIconData(module).component}
                imageAlt={getModIconData(module).alt}>
                        <h1>{title}</h1>
                        <p>{descr}</p>
            </PagePicTitle>

            <Section id={style.DocPreviews}>
                <p id={style.selInstr}>Select a topic below</p>
                {docMani.map(docSect => {
                    if (docSect.type == "section"){
                        return (
                            <div key={docSect.title} className={style.docSection}>
                                <h3 className={style.docSectionTitle}>{docSect.title}</h3>
                                {docSect.posts.map(docSectInner => {
                                    return printDocLink(module, docSect.slug + "/" + docSectInner.slug, docs);
                                })}
                            </div>
                        );
                    }else{
                        return printDocLink(module, docSect.slug, docs);
                    }
                })}
            </Section>
            
        </PageWrap>
    );
}

function printDocLink(module, slug, docs){
    let doc;
    for (let i = 0; i < docs.length; i++) {
        if (docs[i].fields.slug == slug){
            doc = docs[i];
            break;
        }
    }

    if (!doc){
        throw new Error('Doc page not found: '+slug);
    }

    return (
        <IndyLink adr={"/learn/"+module+"/"+doc.fields.slug} key={doc.fields.slug}>
            <div className={style.docPreview}>
                <h2>{doc.frontmatter.title}</h2>
                <p className={style.previewText}>{doc.frontmatter.previewText}</p>
                <p className={style.readingTime}>{doc.fields.readingTime.text}</p>
            </div>
        </IndyLink>
    );
}

//eslint-disable-next-line no-undef
export const query = graphql`
    query SectPosts($module: String) {
        allMarkdownRemark(
            filter: {
                fields: {
                    mdType: {
                        eq: "doc"
                    }, 
                    module: {
                        eq: $module
                    }
                }
            }
        ) {
            nodes {
                fields {
                    slug
                    readingTime {
                        text
                    }
                }
                frontmatter {
                    title
                    previewText
                }
            }
        }
    }
`;